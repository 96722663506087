import {
  Button,
  ButtonWrapper,
  Container,
  Menu,
  NavBar,
  NavBarSection,
  NavItem,
} from "./styled/slideBarComponents";
import {LinkComponent} from "../main/styled/mainComponents";
import React from "react";

interface IProps {
  showBanner: boolean
  onOpen: () => void
  handleModal: () => void;
}

export const SlideMenu = ({ showBanner, onOpen, handleModal }: IProps) => {
  const handleOpenModal = () => {
  onOpen();
  handleModal();
  }
  return (
    <>
      {showBanner && (
        <Container>
          <Menu>
            <NavBarSection>
              <NavBar>
                <NavItem onClick={onOpen} href={"/#about"}>
                  Uslugi
                </NavItem>
                <NavItem onClick={onOpen} href={"/#proces"}>
                  Proces
                </NavItem>
                <LinkComponent onClick={onOpen} to="/gallery">
                  <NavItem>Projekty</NavItem>
                </LinkComponent>
                <NavItem onClick={onOpen} href={"/#contacts"}>
                  Kontakt
                </NavItem>
              </NavBar>
              <ButtonWrapper>
                <Button onClick={handleOpenModal}>Wyślij zapytanie</Button>
              </ButtonWrapper>
            </NavBarSection>
          </Menu>
        </Container>
      )}
    </>
  );
};
