import styled from "styled-components";

export const Container = styled.header<{ headerActive: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 88px;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 130px;
  position: sticky;
  top: ${({ headerActive }) => (headerActive ? "0px" : "-88px")};
  background: #ffffff;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  z-index: 2;
`;

export const LogoOriginal = styled.h1`
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 33px;
  color: #333333;
  margin-left: 20px;
`;

export const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 1200px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const BurgerButtonBlock = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
  }
`;

export const BurgerButton = styled.button`
  display: flex;
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  border: none;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 3px;
`;

export const NavBarSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 40%;
`;

export const NavItem = styled.a`
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #37383a;
  :hover {
    color: #000000;
    cursor: pointer;
    font-weight: 600;
  }
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  width: 200px;
  height: 48px;
  background: #1976d2;
  border: none;
  border-radius: 5px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  :hover {
    cursor: pointer;
    background: #1976f5;
  }
  :disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;
