import { Link } from "react-router-dom";
import styled from "styled-components";
import {NavItem} from "../../index/styled/headerComponents";

export const Section = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.div`
  margin-top: 100px;
  display: flex;
  width: 70%;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;

export const LogoYellow = styled.h2`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-transform: uppercase;
  color: #f6c242;
  margin-top: 20px;
  margin-left: 30px;
`;

export const MessageText = styled.h4`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 150%;
  color: #000000;
  margin-left: 30px;
  padding-top: 10px;
`;

export const InvitationBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("/background.png");
  height: 400px;
  @media (max-width: 767px) {
    background-image: url("/background-mobile.png");
    background-repeat: round;
    height: 150px;
  }
  background-size: cover;
`;

export const InvitationMessageBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const HeadingBlock = styled.div`
  margin-top: 40px;
  width: 40%;
  height: 100%;
  display: flex;
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    margin-top: 20px;
  }
`;

export const ContentBlock = styled.div`
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 100%;
  }
`;

export const Label = styled.h4`
  margin-left: 30px;
  text-transform: uppercase;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 200;
  font-size: 48px;
  line-height: 60px;
  width: 415px;
  @media (max-width: 1366px) {
    max-width: 250px;
    font-size: 32px;
    margin-left: 20px;
  }
`;

export const LabelColoring = styled.span`
  color: #1976d2;
`;

export const ListBlock = styled.div`
  margin-top: 40px;
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 50%;
  }
`;

export const HeadingText = styled.h4`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  padding-right: 10px;
  @media (max-width: 1024px) {
    margin-left: 24px;
  }
`;

export const List = styled.ul`
  margin-top: 20px;
  margin-left: 24px;
  li {
    list-style-type: disc;
    font-family: Inter, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #000000;
  }
  @media (max-width: 1024px) {
    li {
      margin-left: 20px;
    }
  }
`;

export const InfoBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const InfoWrapper = styled.div`
  margin-top: 70px;
`;

export const InfoText = styled.p`
  padding-top: 16px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  text-align: justify;
  @media (max-width: 1024px) {
    padding: 16px 30px 0 30px;
  }
`;

export const InvitationText = styled(InfoText)`
  margin-left: 30px;
  @media (max-width: 1024px) {
    padding: 0;
    width: 310px;
  }
`;

export const CoworkingBlock = styled.div`
  margin-top: 30px;
  padding-left: 40px;
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 767px) {
    width: 85%;
    padding: 0;
    margin-top: 20px;
  }
`;

export const CoworkingText = styled.p`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
`;

export const CoworkingTextBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
`;
export const CoworkingIconBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
`;
export const CoworkingIcon = styled.span`
  font-size: 32px;
  padding-right: 5px;
`;

export const CarouselBlock = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const MorePhotos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const ContactsSection = styled.div`
  display: flex;
  margin-top: 50px;
  width: 100%;
  background-image: url("/contacts-section.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  justify-content: center;
`;

export const ContactsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 458px;
  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
  }
`;

export const ContactsMessage = styled.h4`
  font-family: Inter, serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0;
  text-align: left;
  color: white;
  margin-left: 30px;
  padding-top: 10px;
`;

export const ContactsText = styled(InvitationText)`
  color: white;
`;

export const ContactsInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 30px;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
  margin-left: 6px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: white;
  :hover {
    color: #ffffff;
    cursor: pointer;
    font-weight: 800;
  }
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const LinkComponent = styled(Link)`
  all: unset;
  cursor: pointer;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`;
export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  @media(max-width: 768px ){
    width: 100%;
    flex-direction: column;
  }
`;
export const FooterText = styled.p`
  color: #767A80;
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

export const FooterLink = styled(NavItem)`
  margin-top: 8px;
  color: #1E212B;
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
`;
