import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {IMedia} from "../../common/entities/media";
import styled from "styled-components";

interface IProps {
    mediaObject: IMedia;
    width?: string;
    showThumb?: boolean;
    showLegend?: boolean;
}


export const CarouselComponent = ({mediaObject, showThumb, showLegend = false}: IProps) => {
    const handleImageLoad = (imageUrl: string) => {
        const img = new Image();
        img.src = imageUrl;
        if (img.height > img.width) {
            return 'portrait';
        } else {
            return 'landscape';
        }
    }

    return (
        <CarouselFlex selectedItem={0} emulateTouch useKeyboardArrows axis='horizontal' dynamicHeight={false} thumbWidth={75}
                  showThumbs={showThumb}>
            {mediaObject.files.map((item, index) => (
                <PhotoContainer key={index}>
                    <StyledImage orientation={handleImageLoad(item.path)} src={item.path} alt={item.fileName} onLoad={() => handleImageLoad(item.path)} key={index}/>
                    {showLegend && <p className="legend">{mediaObject.nameOrDate}</p>}
                </PhotoContainer>
            ))}
        </CarouselFlex>
    );
};

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img<{orientation: string}>`
  max-width: 100%;
  width: ${props => props.orientation === 'portrait' ? '50%' : '100%'} !important;
  @media(max-width: 1367px){
    width: ${props => props.orientation === 'portrait' ? '55%' : '100%'} !important;
  }
`;

const CarouselFlex = styled(Carousel)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .slide{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media(orientation: portrait) {
    width: 100%;
  }
  @media(orientation: landscape) {
    width: 100%;
  }
  z-index: 3;
`;
