import Icon, {
    ClockCircleFilled,
    MailFilled,
    PhoneFilled,
} from "@ant-design/icons";
import React, {useEffect} from "react";
import {Header} from "./components/index/Header";
import {ReactComponent as AdditionalLogo} from "./assets/additional.svg";
import {ReactComponent as BuilderLogo} from "./assets/builder.svg";
import {ReactComponent as FunctionalLogo} from "./assets/functional.svg";
import {ReactComponent as SafetyLogo} from "./assets/safety.svg";
import {CarouselComponent} from "./components/gallery/carousel";
import {SlideMenu} from "./components/index/SlideBar";
import {
    Section,
    InvitationBlock,
    Wrapper,
    InvitationMessageBlock,
    LogoYellow,
    MessageText,
    InvitationText,
    HeadingBlock,
    Label,
    LabelColoring,
    ContentBlock,
    ListBlock,
    HeadingText,
    List,
    InfoBlock,
    InfoText,
    InfoWrapper,
    CoworkingBlock,
    CoworkingIcon,
    CoworkingText,
    CarouselBlock,
    ContactsSection,
    ContactsBlock,
    ContactsMessage,
    ContactsText,
    ContactsInfo,
    CustomLink,
    MorePhotos,
    LinkComponent,
    Footer,
    FooterText,
    FooterLink,
    FooterContent,
    CoworkingIconBlock,
    CoworkingTextBlock,
} from "./components/main/styled/mainComponents";
import {Button} from "./components/index/styled/headerComponents";
import {ContactForm} from "./components/index/Contact";

function App() {
    const [showBanner, setShowBanner] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const onOpen = React.useCallback(() => {
        setShowBanner(!showBanner);
    }, [showBanner]);

    useEffect(() => {
        if (showBanner) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [showBanner]);

    const handleModal = () => {
        setShowModal(!showModal);
    }
    return (
        <>
            <ContactForm isModalOpened={showModal} handleModal={handleModal}/>
            <Header isOpened={showBanner} onOpen={onOpen} handleModal={handleModal}/>
            <SlideMenu showBanner={showBanner} onOpen={onOpen} handleModal={handleModal}></SlideMenu>
            <Section>
                <InvitationBlock/>
                <Wrapper>
                    <InvitationMessageBlock>
                        <LogoYellow>R.A honest partners.</LogoYellow>
                        <MessageText>Fachowa pomoc wykończenia wnętrz.</MessageText>
                        <InvitationText>
                            Właśnie dobrze trafiłeś! Nasza firma oferuje profesjonalne usługi wykończenia wnętrz. Wiemy,
                            jak klient wiecznie zmaga się z dylematem, która firma jest najlepsza.
                            Powierz swoje wymarzone „cztery kąty” w dobre ręce!
                        </InvitationText>
                    </InvitationMessageBlock>
                </Wrapper>
                <Wrapper id={"about"}>
                    <HeadingBlock>
                        <Label>
                            CZYM <LabelColoring>SIĘ ZAJMUJEMY</LabelColoring>
                        </Label>
                    </HeadingBlock>
                    <ContentBlock>
                        <ListBlock>
                            <HeadingText>Wykonywanie instalacji elektrycznych</HeadingText>
                            <List>
                                <li>przeróbki elektryczne</li>
                                <li>oświetlenie dekoracyjne</li>
                                <li>oświetlenie liniowe</li>
                                <li>oświetlenie punktowe</li>
                                <li>instalacja audio</li>
                                <li>instalacje teletechniczne, LAN</li>
                            </List>
                        </ListBlock>
                        <ListBlock>
                            <HeadingText>Realizację prac budowlanych</HeadingText>
                            <List>
                                <li>wyburzenie, murowanie</li>
                                <li>tynkowanie, malowanie</li>
                                <li>sucha zabudowa</li>
                                <li>glazura i terakota</li>
                                <li>gładzie gipsowe</li>
                                <li>parkiety i panele podłogowe</li>
                            </List>
                        </ListBlock>
                        <ListBlock>
                            <HeadingText>Wykonywanie instalacji sanitarnych</HeadingText>
                            <List>
                                <li>przeróbki hydrauliczne</li>
                                <li>nowe podejścia wodne i kanalizacyjne</li>
                                <li>przeróbki centralnego ogrzewania</li>
                                <li>stelaże w zabudowie</li>
                                <li>bezbrodzikowe prysznice</li>
                            </List>
                        </ListBlock>
                    </ContentBlock>
                </Wrapper>
                <Wrapper id={"proces"}>
                    <HeadingBlock>
                        <Label>
                            ZAPEWNE CHCESZ WIEDZIEĆ <LabelColoring> JAK DZIAŁA NASZA FIRMA</LabelColoring>
                        </Label>
                    </HeadingBlock>
                    <ContentBlock>
                        <InfoBlock>
                            <HeadingText>Oględziny lokalu</HeadingText>
                            <InfoText>
                                Po wstępnej rozmowie z klientem i przedstawieniu warunków współpracy, przeprowadzamy
                                nieodpłatną wizję lokalną, która pomaga nam w przygotowaniu szczegółowego kosztorysu
                                prac.

                            </InfoText>
                            <InfoWrapper>
                                <HeadingText>Oczekiwania klienta</HeadingText>
                                <InfoText>
                                    Przed przystąpieniem do prac ustalamy z klientem optymalny termin na wykonanie
                                    zlecenia.
                                    Po opracowaniu harmonogramu, strony dokładnie wiedzą jaka będzie kolejność
                                    wykonywania prac, i w jakich ramach czasowych ekipa wyrobi się ze swoimi robotami.

                                </InfoText>
                            </InfoWrapper>
                            <InfoWrapper>
                                <HeadingText>Wycena</HeadingText>
                                <InfoText>
                                    Nasz kosztorys jest podstawą współpracy z klientem. Lata doświadczeń pozwalają nam
                                    doprecyzować wycenę niemal na 100% jeszcze przed przystąpieniem do prac remontowych.
                                    Pozycje w kosztorysie są jasne dla klienta, a ewentualne zmiany w trakcie prac łatwo
                                    rozliczyć w kosztorysie powykonawczym używając ustalonych od samego początku cen
                                    jednostkowych.
                                </InfoText>
                            </InfoWrapper>
                        </InfoBlock>
                    </ContentBlock>
                </Wrapper>
                <Wrapper>
                    <HeadingBlock>
                        <Label>
                            Podstawą do <LabelColoring> współpracy są</LabelColoring>
                        </Label>
                    </HeadingBlock>
                    <ContentBlock>
                        <CoworkingBlock>
                            <CoworkingIconBlock>
                                <CoworkingIcon>
                                    <Icon component={AdditionalLogo}/>
                                </CoworkingIcon>
                            </CoworkingIconBlock>
                            <CoworkingTextBlock>
                                <CoworkingText>  Funkcjonalne wnętrza z gwarancją</CoworkingText>
                            </CoworkingTextBlock>
                        </CoworkingBlock>
                        <CoworkingBlock>
                            <CoworkingIconBlock>
                                <CoworkingIcon>
                                    <Icon component={SafetyLogo}/>
                                </CoworkingIcon>
                            </CoworkingIconBlock>
                            <CoworkingTextBlock>
                                <CoworkingText>
                                    Doradztwo i pomoc w projekcie</CoworkingText>
                            </CoworkingTextBlock>
                        </CoworkingBlock>
                        <CoworkingBlock>
                            <CoworkingIconBlock>
                                <CoworkingIcon>
                                    <Icon component={FunctionalLogo}/>
                                </CoworkingIcon>
                            </CoworkingIconBlock>
                            <CoworkingTextBlock>
                                <CoworkingText>Bezpieczeństwo i wygoda</CoworkingText>
                            </CoworkingTextBlock>
                        </CoworkingBlock>
                        <CoworkingBlock>
                            <CoworkingIconBlock>
                                <CoworkingIcon>
                                    <Icon component={BuilderLogo}/>
                                </CoworkingIcon>
                            </CoworkingIconBlock>
                            <CoworkingTextBlock>
                                <CoworkingText>Wykwalifikowany zespół</CoworkingText>
                            </CoworkingTextBlock>
                        </CoworkingBlock>
                    </ContentBlock>
                </Wrapper>
                <Wrapper id={"projects"}>
                    <HeadingBlock>
                        <Label>
                            Zrealizowane <LabelColoring>projekty </LabelColoring>
                        </Label>
                    </HeadingBlock>
                    <ContentBlock>
                        <CarouselBlock>
                            <CarouselComponent
                                showThumb={false}
                                mediaObject={{
                                    objectId: '1',
                                    nameOrDate: 'Mieszkanie',
                                    files: [{fileName: 'file.jpg', path: '/carousel1.jpeg'}, {fileName: 'file.jpg', path: '/carousel3.jpeg'}, {fileName: 'file.jpg', path: '/carousel2.jpeg'}]
                                }}
                            />
                        </CarouselBlock>
                        <MorePhotos>
                            <LinkComponent to="/gallery">
                                <Button>Do Galerii</Button>
                            </LinkComponent>
                        </MorePhotos>
                    </ContentBlock>
                </Wrapper>
                <ContactsSection>
                    <Wrapper id="contacts">
                        <ContactsBlock>
                            <LogoYellow>R.A honest partners</LogoYellow>
                            <ContactsMessage>Masz pytania? Chętnie pomożemy.</ContactsMessage>
                            <ContactsText>
                                Pokaż nam co potrzebujesz, a my zadzwonimy do Ciebie i
                                przedstawimy rozwiązanie.
                            </ContactsText>
                        </ContactsBlock>
                        <ContactsBlock>
                            <ContactsInfo>
                                <ContactsText>
                                    <ClockCircleFilled/> Pon-Pt: 8:00-17:00
                                </ContactsText>
                                <ContactsText>
                                    <PhoneFilled/>
                                    <CustomLink to="tel:+48 788 196 566">
                                        +48 788 196 566
                                    </CustomLink>
                                </ContactsText>
                                <ContactsText>
                                    <MailFilled/>
                                    <CustomLink to="mailto:r.a.honest.partners@gmail.com">
                                        r.a.honest.partners@gmail.com
                                    </CustomLink>
                                </ContactsText>
                            </ContactsInfo>
                        </ContactsBlock>
                    </Wrapper>
                </ContactsSection>
                <Footer>
                    <FooterContent>
                        <div><FooterText>© R.A Honest Partners {new Date().getFullYear()}. All rights
                            reserved.</FooterText></div>
                        <div><FooterLink target="_blank" href={'/RODO.pdf'}>RODO</FooterLink></div>
                        <div><FooterLink target="_blank" href={'/R.A Honest Partners - Privacy Policy.pdf'}>Privacy
                            Policy</FooterLink></div>
                    </FooterContent>
                </Footer>
            </Section>
        </>
    );
}

export default App;
