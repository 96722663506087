import Icon from "@ant-design/icons/lib/components/Icon";
import {ReactComponent as PolishFlag} from "./assets/polish-flag.svg";
import {useMemo} from "react";
import styled from "styled-components";

const FlagIcon = styled(Icon)`
padding: 5px;
`;
export const usePhoneNumberRegions = () => {
    return useMemo(() => [
        {
            value: 'PL',
            label: <p><FlagIcon component={PolishFlag}></FlagIcon> +48</p>
        },
    ], []);
}

