import React, {ChangeEvent, useCallback, useState} from 'react';
import styled from 'styled-components';
import {Button} from "./styled/headerComponents";
import axios from 'axios';
import {CountryCode, isValidPhoneNumber} from 'libphonenumber-js'
import {usePhoneNumberRegions} from "../../usePhoneNumberRegions";
import {Select, notification} from 'antd';

interface IProps {
    isModalOpened: boolean
    handleModal: () => void
}
export const ContactForm = ({isModalOpened, handleModal}: IProps) => {
    const [formData, setFormData] = useState({userFullName: '', userEmail: '', userPhoneNumber: '', description: ''});
    const [checkBoxClicked, setCheckBoxClicked] = useState(false);
    const [region, setRegion] = useState<CountryCode>('PL');
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const options = usePhoneNumberRegions();

    const clearState = () => {
        setFormData({userFullName: '', userEmail: '', userPhoneNumber: '', description: ''})
        setCheckBoxClicked(false);
        setRegion('PL');
        setErrorEmail(false);
        setErrorPhone(false);
    }

    const modalOnClose = useCallback(() => {
        clearState();
        handleModal();
    },[handleModal]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateEmail(formData.userEmail)) {
            setErrorEmail(true);
            return;
        }
        if(!isValidPhoneNumber(formData.userPhoneNumber, region)){
            setErrorPhone(true);
            return;
        }
        try {
           await axios.post(`${process.env.REACT_APP_SERVER_ORIGIN}/mail/send`, formData);
            notification.success({
                message: 'Formularz został wysłany',
                description: 'Wniosek został wysłany pomyślnie, skontaktujemy się z Tobą, gdy tylko go sprawdzimy.',
            });
        } catch (error) {
            notification.error({
                message: 'Ups, wystąpił błąd',
                description: 'Przepraszamy, usługa jest tymczasowo niedostępna',
            });
            console.error('Form submission error:', error);
        }
        modalOnClose();
    };
    const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const handleSelectChange = (region: CountryCode) => {
        setRegion(region);
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(errorEmail && e.target.name === 'userEmail'){
            setErrorEmail(false);
        }
        if(errorPhone && e.target.name === 'userPhoneNumber'){
            setErrorPhone(false);
        }
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const handleOverlayClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            modalOnClose();
        }
    };
    if (!isModalOpened) {
        return null;
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    // @ts-ignore
    return (
        <>
            <Overlay onClick={handleOverlayClick}/>
            <ModalContainer>
                <ModalHeader>
                    <ModalHeaderText>Wyślij zapytanie</ModalHeaderText>
                    <CloseButton onClick={modalOnClose}>&times;</CloseButton>
                </ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <InputWrapper>
                        <LabelRequired>Imię i Nazwisko:</LabelRequired>
                        <Input
                            type="text"
                            name="userFullName"
                            value={formData.userFullName}
                            onChange={handleChange}
                            color={'#3fc1c9'}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <LabelRequired>Email:</LabelRequired>
                        <Input
                            type="email novalidate/"
                            name="userEmail"
                            value={formData.userEmail}
                            onChange={handleChange}
                            color={errorEmail ? 'red' : '#3fc1c9'}
                        />
                        {errorEmail && <ErrorMessage>Prosimy o podanie prawidłowego adresu e-mail</ErrorMessage>}
                    </InputWrapper>
                    <InputWrapper>
                        <LabelRequired>Numer telefonu:</LabelRequired>

                            <Select
                                defaultValue={region}
                                style={{ width: '40%' }}
                                options={options}
                                onSelect={handleSelectChange}
                            />
                        <Input
                            type="text"
                            name="userPhoneNumber"
                            value={formData.userPhoneNumber}
                            onChange={handleChange}
                            color={errorPhone ? 'red' : '#3fc1c9'}
                        />
                        {errorPhone && <ErrorMessage>Prosimy o podanie prawidłowego numeru telefonu</ErrorMessage>}
                    </InputWrapper>
                    <InputWrapper>
                        <Text>Opisz swoje wymagania:</Text>
                        <TextArea
                            name="description"
                            value={formData.description}
                            onChange={handleTextareaChange}
                            rows={4}
                            cols={50}
                        />
                        <CheckBoxWrapper>
                            <CheckBox type={'checkbox'} onClick={() => setCheckBoxClicked(!checkBoxClicked)}></CheckBox>
                            <LabelRequiredBefore> Oświadczam, że znam i akceptuję postanowienia <a rel="noreferrer"
                                                                                                   target="_blank"
                                                                                                   href={'/RODO.pdf'}>R.A
                                Honest Partners</a></LabelRequiredBefore>
                        </CheckBoxWrapper>
                    </InputWrapper>
                    <ButtonContainer>
                        <Button type="submit"
                                disabled={!checkBoxClicked || formData.userFullName === '' || formData.userEmail === '' || formData.userPhoneNumber === ''}>Wysłać</Button>
                    </ButtonContainer>
                </Form>
            </ModalContainer>
        </>
    );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 12;
  border-radius: 8px;
  width: 80%;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 40%;
  }
`;

const ModalHeader = styled.div`
  background: #FFFFFF;
  width: 100%;
  z-index: 13;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-topright: 8px;
  border-bottom: 1px solid black;
`;


const ModalHeaderText = styled.h2`
  font-family: Inter, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  color: #000000;
  padding-left: 15px;
`;

const Text = styled.label`
  font-family: Inter, serif;
  font-style: normal;
  font-size: 14px;
  line-height: 145%;
  color: #000000;
`;

const LabelRequired = styled(Text)`
  :after {
    content: " *";
    color: red;
  }
`;

const LabelRequiredBefore = styled(Text)`
  :before {
    content: " *";
    color: red;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 10px;
`;

const Input = styled.input<{color: string}>`
  width: 100%;
  margin-top: 10px;
  border: 0;
  font-family: Inter, serif;
  font-size: 14px;
  border-bottom: 1px solid black;

  :focus {
    outline: 0;
    border-bottom-color: ${props => props.color};
  }

  :after {
    content: " *";
    color: red;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  border: 0;
  margin-top: 10px;
  font-family: Inter, serif;
  font-size: 14px;
  border-bottom: 1px solid black;

  :focus {
    outline: 0;
    border-bottom-color: #3fc1c9;
  }
`;

const CheckBox = styled.input`
  margin-right: 8px;
`
const CheckBoxWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  font-family: Inter, serif;
  font-size: 14px;
  color: red;
  line-height: 146%;
`;
