import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { StateStatuses } from "../../../../enums/stateStatuses.enum";
import { IMedia } from "../interfaces/files.interface";

export const fetchMedia = createAsyncThunk<
  IMedia[],
  void,
  { rejectValue: string }
>("photos/fetchMedia", async function (_, { rejectWithValue }) {
  try {
    const { data } = await axios.get<IMedia[]>(
      `${process.env.REACT_APP_SERVER_ORIGIN}/media/load-all`
    );

    if (!data) {
      new Error();
    }
    return data;
  } catch (err) {
    return rejectWithValue("Unexpected error");
  }
});

interface MediaState {
  files: IMedia[];
  status: StateStatuses;
  error?: string;
}

const initialState: MediaState = {
  files: [],
  status: StateStatuses.INIT,
  error: undefined,
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMedia.pending, (state, action) => {
      state.status = StateStatuses.LOADING;
      state.error = undefined;
    });
    builder.addCase(fetchMedia.fulfilled, (state, action) => {
      state.files = action.payload;
      state.status = StateStatuses.SUCCESS;
      state.error = undefined;
    });
    builder.addCase(fetchMedia.rejected, (state, action) => {
      state.status = StateStatuses.ERROR;
      state.error = action.payload;
    });
  },
});

export const { actions: mediaActions } = mediaSlice;
export const { reducer: mediaReducer } = mediaSlice;