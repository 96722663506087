import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/reset.css';
import './css/index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './components/main/errorPage';
import { GalleryComponent } from './components/gallery';
import { Provider } from 'react-redux';
import { store } from './common/store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/gallery",
    element: <GalleryComponent/>
  }
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

