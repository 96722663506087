import React, {useCallback, useEffect, useState} from "react";
import {Header} from "../index/Header";
import {SlideMenu} from "../index/SlideBar";
import {useDispatch, useSelector} from "react-redux";
import {fetchMedia} from "../../common/entities/media/model/slice/mediaSlicer";
import {AppDispatch} from "../../common/store/store";
import {getMedia} from "../../common/entities/media/model/selectors/getMedia.selector";
import {Section, Wrapper} from "../main/styled/mainComponents";
import styled from "styled-components";
import {Card, Image} from "antd";
import Meta from "antd/es/card/Meta";
import {CarouselComponent} from "./carousel";
import {CloseCircleFilled} from "@ant-design/icons";
import { createPortal } from 'react-dom';
import {ContactForm} from "../index/Contact";

export const GalleryComponent = () => {
    const [showBanner, setShowBanner] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const media = useSelector(getMedia);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [mediaId, setMediaId] = useState<number | null>(null);
    const [loaderIsOpened, setLoaderIsOpened] = useState(true);
    const [showModal, setShowModal] = React.useState(false);
    function openModal(mediaId: number) {
        setIsOpen(true);
        setMediaId(mediaId);
    }
    function handleOverlayClick (event: React.MouseEvent) {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    }
    function closeModal() {
        setIsOpen(false);
        setMediaId(null);
    }

    const onOpen = useCallback(() => {
        setShowBanner(!showBanner);
    }, [showBanner]);

    useEffect(() => {
        dispatch(fetchMedia());
        setTimeout(() => {
            setLoaderIsOpened(false)
        }, 2000)
        if (showBanner) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [showBanner, loaderIsOpened, dispatch]);
    const handleModal = () => {
        setShowModal(!showModal);
    }
    return (
        <>
            <Header isOpened={showBanner} onOpen={onOpen} handleModal={handleModal}/>
            <ContactForm isModalOpened={showModal} handleModal={handleModal}/>
            <SlideMenu showBanner={showBanner} onOpen={onOpen} handleModal={handleModal}></SlideMenu>
            <Section>
                <Wrapper>
                    {loaderIsOpened ? <LoaderWrapper><Loader/></LoaderWrapper> :
                    <GalleryContent>
                        {media.length > 0 ? <>
                        {media.map((mediaObject, index) => {
                            return (
                                <Card
                                    hoverable
                                    style={{width: 300, marginBottom: 15}}
                                    onClick={() => openModal(index)}
                                    cover={<Image
                                        height={200}
                                        src={mediaObject.files[0].path}
                                        alt={mediaObject.files[0].fileName}
                                        preview={false}
                                    ></Image>}
                                >
                                    <Meta title={mediaObject.nameOrDate}/>
                                </Card>
                            );
                        })}
                        {modalIsOpen &&
                            <>
                            {createPortal(<StyledModalOverlay onClick={(e) => handleOverlayClick(e)}>
                                <StyledModalContent>
                                <CloseCrossContainer onClick={closeModal}><CloseCircleFilled></CloseCircleFilled></CloseCrossContainer>
                                <CarouselComponent showThumb={false}
                                                   mediaObject={media[mediaId as number]}
                                ></CarouselComponent>
                                </StyledModalContent>
                            </StyledModalOverlay>, document.body)
                            }
                            </>
                        }
                        </> : <NoImagesText>Nie ma jeszcze nic do pokazania... </NoImagesText>}
                    </GalleryContent>}
                </Wrapper>
            </Section>
        </>
    );
};

const GalleryContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  border-radius: 15px;
  background: #F5F8FC;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    width: 85%;
  }
`;

const StyledModalOverlay = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow-y: hidden;
  z-index: 3;
`;

const StyledModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media(orientation: landscape){
    width: 50%;
    height: 50%;
  }
`;

const CloseCrossContainer = styled.p`
  position: absolute;
  cursor: pointer;
  top: 50px;
  right: 40px;
  font-size: 24px;
  color: #808080FF;
  :hover{
    color: #FFFFFF;
  }
    z-index: 4;
`

const NoImagesText = styled.p`
    font-weight: 600;
    font-family: 'Inter', serif;
    text-align: center;
    font-size: 24px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;
const Loader = styled.span`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
   ::before , ::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 5px solid  #f6c242;
    animation: prixClipFix 2s linear infinite ;
  }
  ::after{
    border-color:  #1976d2;
    animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
    inset: 6px;
  }

  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }
`;
