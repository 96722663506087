import styled, { keyframes } from "styled-components";

export const animation = keyframes`
    0%   {width: 0;}
    50% {width: 160px;}
    100% {width: 320px;}
  `;

export const Container = styled.div`
  @media (max-width: 1200px) {
    display: flex;
    position: fixed;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export const Menu = styled.div`
  width: 320px;
  background-color: #ffffff;
  animation: ${animation} 0.2s linear;
`;

export const NavBarSection = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const NavItem = styled.a`
  padding: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #37383a;
  :hover {
    color: #000000;
    cursor: pointer;
    font-weight: 800;
  }
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  width: 200px;
  height: 48px;
  background: #1976d2;
  border: none;
  border-radius: 5px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  :hover {
    cursor: pointer;
    background: #1976f5;
  }
`;
